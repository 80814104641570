import { SerializedStyles } from '@emotion/react';
import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Textarea from '../components/Textarea';
import Radio from '../components/Radio';
import Checkbox from '../components/Checkbox';
import styled, { theme, hachureStyles, scale, baseline, BREAKPOINTS } from '../styled';
import Button, { ButtonVariant } from '../components/Button';
import ButtonLink from '../components/ButtonLink';
import CenteredBox from '../components/CenteredBox';
import Carousel from '../components/Carousel';
import RequestQuoteBlock from '../components/RequestQuoteBlock';
import ServicesBlock from '../components/ServicesBlock';
import {
  IconArrowRight,
  IconArrowLeft,
  IconBicycle,
  IconChevronLeft,
  IconChevronRight,
  IconEnvelope,
  IconFacebook,
  IconInstagram,
  IconLock,
  IconMail,
  IconMapMarker,
  IconPackage,
  IconPhoneHandset,
  IconQuotation,
  IconSpinner,
  IconTrees,
} from '../components/icons';
import SocialIconLink from '../components/SocialIconLink';
import ReassuranceBlock from '../components/ReassuranceBlock';

const BoxHachure = styled.div<{ type: keyof typeof theme.hachure }>`
  margin-bottom: ${scale(1)};
  padding: ${scale(1)};
  ${({ type, theme }): SerializedStyles => hachureStyles(theme.hachure[type])}
  background-color: ${({ type, theme }): string => (type === 's' ? theme.color.green.m : '')}
`;

const Slide = styled.div`
  padding: ${scale(1)};
  width: 100%;

  background-color: ${({ theme }): string => theme.color.gray.s};
  text-align: center;
  ${baseline(14, 1, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(16, 1, 'l')};
  }
`;

const Icons: React.FC = ({ children }) => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement<{ style: React.CSSProperties }>, {
          style: { margin: scale(1) },
        });
      })}
    </div>
  );
};

const UiKitPage: React.FC = () => (
  <Layout hasBlocks={false}>
    <SEO />
    <section data-testid="html-tags">
      <CenteredBox>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at
          felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec
          eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit
          sit amet quam. Vivamus pretium ornare est.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at
          felis aliquet congue.
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at
          felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec
          eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit
          sit amet quam. Vivamus pretium ornare est. Ut a est eget ligula molestie gravida.{' '}
          <a href="#anchor">Curabitur massa</a>. Donec eleifend, libero at sagittis mollis, tellus
          est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.
        </p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

        <h1>Header Level 1</h1>
        <h2>Header Level 2</h2>
        <h3>Header Level 3</h3>
        <h4>Header Level 4</h4>

        <p>
          <strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada
          fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit
          amet, ante. Donec eu libero sit amet quam egestas semper.{' '}
          <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est
          et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed,{' '}
          <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt
          condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui.{' '}
          <a href="https://www.greencourse.fr/">Donec non enim</a> in turpis pulvinar facilisis. Ut
          felis.
        </p>

        <ul>
          <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
          <li>Ut a est eget ligula molestie gravida.</li>
          <li>
            Aliquam tincidunt mauris eu risus.
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
              <li>Aliquam tincidunt mauris eu risus.</li>
            </ul>
          </li>
        </ul>

        <ol>
          <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
          <li>Aliquam tincidunt mauris eu risus.</li>
          <ol>
            <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
            <li>Aliquam tincidunt mauris eu risus.</li>
          </ol>
        </ol>

        <blockquote>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at
            felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec
            eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit
            sit amet quam. Vivamus pretium ornare est.
          </p>
        </blockquote>

        <pre>
          Mauris placerat eleifend leo
          <code>
            {`
#header h1 a {
  display: block;
  width: 300px;
  height: 80px;
}`}
          </code>
        </pre>
      </CenteredBox>
    </section>
    <section data-testid="form-elements">
      <CenteredBox>
        <Input placeholder="Label placeholder" id="input">
          Input field
        </Input>
        <Input placeholder="Label placeholder" id="input" aria-invalid={true}>
          Input field with error
        </Input>
        <Textarea placeholder="Label placeholder" id="textarea">
          Textarea field
        </Textarea>
        <Textarea placeholder="Label placeholder" id="textarea" aria-invalid={true}>
          Textarea field with error
        </Textarea>
        <Radio id="radio-1" value="radio-1" name="radios">
          Radio field #1
        </Radio>
        <Radio id="radio-2" value="radio-2" name="radios">
          Radio field #2
        </Radio>
        <Radio id="radio-error-2" value="radio-error-2" name="radios" aria-invalid={true}>
          Radio field error #2
        </Radio>
        <Checkbox id="checkbox-1" value="checkbox-1" name="checkboxes">
          Checkbox field #1
        </Checkbox>
        <Checkbox id="checkbox-2" value="checkbox-2" name="checkboxes">
          Checkbox field #2
        </Checkbox>
        <Checkbox
          id="checkboxe-error-2"
          value="checkboxe-error-2"
          name="checkboxes"
          aria-invalid={true}
        >
          Checkbox field error #2
        </Checkbox>
        <p>
          <Button>Primary button</Button>
        </p>
        <p>
          <Button variant={ButtonVariant.OUTLINED}>Outlined button</Button>
        </p>
        <p>
          <ButtonLink to="#">Primary button link</ButtonLink>
        </p>
        <p>
          <ButtonLink to="#" variant={ButtonVariant.OUTLINED}>
            Outlined button link
          </ButtonLink>
        </p>
      </CenteredBox>
    </section>
    <section data-testid="icons-elements">
      <CenteredBox>
        <p>
          <SocialIconLink href="#" icon={<IconFacebook />}>
            IconFacebook link
          </SocialIconLink>
        </p>
        <Icons>
          <IconArrowLeft>Icon arrow left</IconArrowLeft>
          <IconArrowRight>Icon arrow right</IconArrowRight>
          <IconBicycle>Icon bicycle</IconBicycle>
          <IconChevronLeft>Icon chevron left</IconChevronLeft>
          <IconChevronRight>Icon chevron right</IconChevronRight>
          <IconEnvelope>Icon envelope</IconEnvelope>
          <IconFacebook>Icon Facebook</IconFacebook>
          <IconInstagram>Icon Instagram</IconInstagram>
          <IconLock>Icon lock</IconLock>
          <IconMail>Icon mail</IconMail>
          <IconMapMarker>Icon map marker</IconMapMarker>
          <IconPackage>Icon package</IconPackage>
          <IconPhoneHandset>Icon phone handset</IconPhoneHandset>
          <IconQuotation>Icon quotation</IconQuotation>
          <IconSpinner>Icon spinner </IconSpinner>
          <IconTrees>Icon trees</IconTrees>
        </Icons>
      </CenteredBox>
    </section>
    <CenteredBox>
      <Carousel
        id="carousel"
        data-testid="carousel"
        configs={[
          { breakpoint: BREAKPOINTS.xs, items: 2 },
          { breakpoint: BREAKPOINTS.s, items: 3 },
          { breakpoint: BREAKPOINTS.m, items: 4 },
        ]}
      >
        <Slide>Slide 1</Slide>
        <Slide>Slide 2</Slide>
        <Slide>Slide 3</Slide>
        <Slide>Slide 4</Slide>
        <Slide>Slide 5</Slide>
        <Slide>Slide 6</Slide>
        <Slide>Slide 7</Slide>
        <Slide>Slide 8</Slide>
        <Slide>Slide 9</Slide>
        <Slide>Slide 10</Slide>
      </Carousel>

      <Carousel
        id="carousel-no-pagination"
        data-testid="carousel-no-pagination"
        hasPagination={false}
      >
        <Slide>Slide 1</Slide>
        <Slide>Slide 2</Slide>
        <Slide>Slide 3</Slide>
        <Slide>Slide 4</Slide>
      </Carousel>

      <Carousel id="carousel-one-slide" data-testid="carousel-one-slide">
        <Slide>Slide 1</Slide>
        <Slide>Slide 2</Slide>
        <Slide>Slide 3</Slide>
        <Slide>Slide 4</Slide>
      </Carousel>
    </CenteredBox>

    <BoxHachure type="s" />
    <BoxHachure type="m" />
    <BoxHachure type="l" />
    <ServicesBlock data-testid="block-services" />
    <RequestQuoteBlock data-testid="block-request-quote" />
    <ReassuranceBlock id="block-reassurance" data-testid="block-reassurance" />
  </Layout>
);

export default UiKitPage;
