import React, { InputHTMLAttributes, LabelHTMLAttributes } from 'react';

import styled, { scale, BREAKPOINTS, baseline } from '../styled';

const Box = styled.div`
  margin-bottom: ${scale(1)};
`;

const Label = styled.label<LabelHTMLAttributes<HTMLLabelElement>>`
  position: relative;
  display: block;
  ${baseline(14, 1, 'm')};

  margin-left: ${scale(2)};
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(16, 1, 'l')};
  }

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: ${scale(1)};
    height: ${scale(1)};
    background-color: #fff;
    top: 50%;
    left: ${scale(-2)};
    transform: translateY(-50%);
    border: 1px solid ${({ theme }): string => theme.color.gray.l};
    border-radius: 50%;
    transition: border-color ${({ theme }): string => theme.timing.s};
    will-change: border-color;
  }

  &:hover {
    &:before {
      border-color: #000;
    }
  }

  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: ${scale(0.75)};
    height: ${scale(0.75)};
    box-shadow: inset 0 0 0 ${({ theme }): string => theme.color.green.m};
    top: 50%;
    left: ${scale(-1.875)};
    transform: translateY(-50%);

    border-radius: 50%;
    transition: box-shadow ${({ theme }): string => theme.timing.s};
    will-change: box-shadow;
  }
`;

const Field = styled.input<InputHTMLAttributes<HTMLInputElement>>`
  display: none;

  &:checked {
    + label {
      &:before {
        border-color: ${({ theme }): string => theme.color.green.m};
      }
      &:after {
        box-shadow: inset 0 0 ${scale(1.5)} ${({ theme }): string => theme.color.green.m};
      }
    }
  }

  &[aria-invalid='true'] {
    + label {
      &:before {
        border-color: ${({ theme }): string => theme.color.red.m};
      }
    }
  }
`;

const Radio: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ children, ...props }) => {
  return (
    <Box>
      <Field {...props} type="radio" />
      <Label htmlFor={props.id} tabIndex={0}>
        {children}
      </Label>
    </Box>
  );
};

export default Radio;
